import {
  ref,
  watch,
  computed
} from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import {
  title
} from '@core/utils/filter'

import {
  useToast
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDatasList() {
  // Use toast
  const modulename = "offerletters";
  const modulenamesub = "offerletters";
  const GENAPP_APP_STORE_MODULE_NAME = "salaryslips-offerletters";

  const toast = useToast()

  const refDataListTable = ref(null)

  // Table Handlers
  const tableColumns = [{
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'size',
      label: 'Size',
      sortable: true
    },
    {
      key: 'created_at',
      label: 'Date',
      sortable: true
    },
    {
      key: 'actions',
      label: '',
      sortable: false
    },
  ]
  const perPage = ref(10)
  const totalDatas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDatas.value,
    }
  })

  const refetchData = () => {
    if (refDataListTable.value) {
      refDataListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDatas = (ctx, callback) => {
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + '/fetchDatas', {
        module:'kids_offerletters',
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value ? sortBy.value : 'created_at',
        sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const datas = response.data
        const total = response.data.length

        callback(datas)
        totalDatas.value = total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: "Couldnt find " + modulenamesub + " details",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deletefun = (ctx, callback) => {
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + '/deleteData', {
        id: ctx,
      })
      .then(response => {
        refetchData()

      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: "Couldnt delete " + modulenamesub + " details",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  const downloadfun = (ctx, callback) => {
    axios({
      url: 'https://userfrp.smartfrp.com/api/documents/download/' + ctx.id,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', (ctx.meta.name||''));
      document.body.appendChild(fileLink);
      fileLink.click();
    });
    }

  // *=======================---*
  // *--------- UI ---------------------------------------*
  // *=======================---*

  const resolveDataRoleVariant = is_active => {
    if (is_active) return 'primary'
    else return 'danger'
  }

  return {
    fetchDatas,
    deletefun,
    tableColumns,
    perPage,
    currentPage,
    totalDatas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDataListTable,
    downloadfun,
    resolveDataRoleVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
